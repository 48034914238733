@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*height: calc(100% - 60px);*/
  background: #F9F7F5;


}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#mainarea {
  margin-left: 17px;
  margin-right: 17px;
  border-radius: 32px;
  overflow: hidden;
}

nav {
  color: #242324;
}

nav#mainnav li {
  font-family: GeneralSans-Variable;
  font-size: 14px;
  font-weight: 500;
  line-height: 72px;
  padding: 0px 10px 0px 10px;
  color: #242324;
}

nav li a.active {
  font-weight: 600;
}

#user {
  color:  #242324;
  border: 1px solid #242324;
  margin-right: 20px;
  border-radius: 20px;
  line-height: 40px;
  width: 40px;
  text-align: center;
  float: left;
  display: inline-block;
  padding: 0px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.navlogo img {
  margin-left: 20px;
  margin-top: 27px;
}

#playarea {
  position: relative;
  height: calc(100% - 60px);
}

#content {
  margin:32px;
  margin-top: 32px;
  width: 100%;
  overflow-y: auto;
}


.releaseasset {
  border-radius: 16px;
  background: #FFF;
  box-shadow: 0px 20px 34px 0px rgba(0, 0, 0, 0.10);
  color: var(--Brand-5, #242324);
  text-align: center;
  font-family: GeneralSans-Variable;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height:  32.4px;
  padding: 64px;
}

.lyricsTextarea {
  width: 100%;
  border: 1px dotted #242424;
  padding: 20px;
  resize: none;
  border-radius: 13px;
  height: 100%;
}

.releaseasset img {
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
.releaseasset a {
  color: #9B6CFF;
}

.apicost {
  position: absolute;
  right: 100px;
  line-height: 74px;
}


.lyricsasset {
  width: 100%;
}

.bioArtistName {
  font-family: GeneralSans-Variable;
  font-size: 22px;
  font-weight: 600;
}

.bioSearchTitle {
  font-weight: 600;
}

.bioStrong {
  font-weight: 600;
}

#artists {
  position: relative;
  height: 100%;
}

.homemain, .graymain {
  background-color: #eeeceb;
}

#artists {
  font-family: ClashDisplay-Variable;
  background-color: #242324;
  color: white;
}

#artistHeader {
  height: 45px;
}

#addArtist {
  height: 40px;
  width: 40px;
  text-align: center;
  color: white;
  font-size: 40px;
  line-height: 40px;
  position: absolute;
  right: 21px;
  top: 21px;
  background: #9B6CFF;
  border-radius: 20px;
  cursor: pointer;
}

#artists-title {
  font-size: 21px;
  font-weight: 600;
  line-height: 29px;
  margin-top: 27px;
  margin-left: 31px;

}

.artist {
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  text-align: left;
  padding-left: 31px;
  height: 88px;
  padding-top: 24px;
  display: block;
  position: relative;
}

.artistNameWithInput {
  width: 100%;
}

.artistInput {
  width: 100%;
  outline: none;
  border: 1px dotted #ffffff;
  border-radius: 8px;
  background-color: #242324;
  margin-left: -5px;
  padding: 0px 6px;
}

.saveButton {
  background-color: #242424;
  color: #ffffff;
  border-radius: 8px;
  position: absolute;
  right: -80px;
  top: 22px;

}

.uploadpopup {
  border-radius: 16px;
}

.artistImage {
  width: 40px;
  height:40px;
  border-radius: 20px;
  float: left;
}

.artistNameAndLevel {
  margin-left: 12px;
  float: left;
  width: calc(100% - 65px);
}

.artistSave {
  background-color: #242424;
  border-radius: 13px;
  color: #fff;
  padding: 4px 15px;
  position: absolute;
  right: -85px;
  top: 22px;
  z-index: 1001;
}

.artistLevel {
  font-family: GeneralSans-Variable;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.1em;
  color: #F9F7F5;
}

.selectedArtist {
  background: #B5F267;
  color: #242424;
}

.selectedArtist .artistLevel {
  color: #242424;

}

.artistContent {
  height: 40px;
}

nav.homenav {
  height: 102px;
  padding-top: 32px;
  padding-left: 38px;
}


nav.homenav li {
  color: #242424;
  font-family: GeneralSans-Variable;
  font-size: 16px;
  font-weight: 500;
  line-height: 38px;
}

nav.homenav li a {
  display: inline-block;
  padding: 0px 20px 0px 20px;
}

nav.homenav li a.active {
  font-weight: 600;
  letter-spacing: 0em;
  color: white;
  background: #242424;
  border-radius: 19px;
}

nav.releasenav {
  height: 102px;
}


nav.releasenav li {
  color: #242424;
  font-family: GeneralSans-Variable;
  font-size: 14px;
  font-weight: 500;
  line-height: 38px;
}

nav.releasenav li a {
  display: inline-block;
  padding: 0px 20px 0px 20px;
}

nav.releasenav li a.active {
  font-weight: 500;
  letter-spacing: 0em;
  color: white;
  background: #242424;
  border-radius: 19px;
}

.saveLinks {
  background: #242424;
  color: white;
  font-weight: bold;
  line-height: 30px;
  padding: 0px 12px 0px 12px;
  border-radius: 15px;
  margin-bottom: 20px;
}

#createrelease {
  position: absolute;
  left: 0;
  right: 0;
  top: -180px;
  bottom: 0;
  margin: auto;
  height: auto;
  width: 200px;
  height: 40px;
  background: #242424;
}




.release {
  width: 319px;
  height: 376px;
  border-radius: 16px;


  display: block;
  float: left;
  margin: 10px;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  background: #F9F7F5;

}

.releaseName {
  line-height: 57px;
  font-family: ClashDisplay-Variable;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 20px;
}

.releaseCover {
  width: 319px;
  height: 319px;
  display: block;
}

.release-new {

  line-height: 360px!important;
  background: #EEECEB;
  border: 1px dashed #242424;
}

.uploadarea {
  width: 500px;
  height: 300px;
  text-align: center;
  line-height: 292px;
  padding: 12px;
}

.popupclose {
  font-family: ClashDisplay-Variable;
  font-weight: 500;
  line-height: 14px;
}

.popupfilelist {
  line-height: normal;
}

#analysis {
  background-color: #ffffff;
  margin: 16px 16px 16px 0px;
  border-radius: 24px;
  padding: 0px 16px 16px 16px;
}

.analysistitle {
  font-family: ClashDisplay-Variable;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
  margin-top: 32px;
  margin-bottom: 16px;
}

.analysistitle::first-letter {
    text-transform: uppercase;
}

.releasealbumthumb {
  width: 168px;
  height: 168px;
  border-radius: 8px;
  line-height: 164px;
  font-family: GeneralSans-Variable;
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  color: #222222;
}

.noarrow {
  color: transparent;
}

.releasetitle {
  color: var(--Brand-5, #242324);
  font-family: ClashDisplay-Variable;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  padding-bottom: 18.4px;
  margin-top: 32px;
  margin-bottom: 7px;
  max-width: 550px;
}

.breadcrumb {
  font-family: GeneralSans-Variable;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.6px;
}

.bcparent {
  color: rgba(36, 35, 36, 0.40);
}

.tags li {
  float: left;
  display: inline-block;
  border-radius: 18px;
  padding: 7px 12px 8px 12px;
  margin: 6px 12px 6px 0px;
  font-family: GeneralSans-Variable;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 20px;

}

.tags.releasemusicanalysis li {
  background-color: #B5F267;
  color: #242424;
}


.tags.releaselyricsanalysis li {
  background-color: #242424;
  color: #F9F7F5;
}

.tags strong {
  display: inline-block;
  margin-top: 15px;
  margin-bottom: 5px;
}

.tags.releaselyricsanalysis li.summary {
  color: #000000;
  padding: 10px;
  font-weight: 500!important;
  background-color: white!important;
}

.tags .meta_title, .tags .meta_artist {
  display: none;
}


.tags {
  /*padding: 20px;*/
}

.sliders-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  width: 100%;
}

.slider-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  /*align-items: flex-end;*/
  height: 76px;
  border-radius: 16px;
  background-color: rgba(36, 35, 36, 0.05);
  padding: 0px 24px 24px 24px;
  position: relative;
}

.slider-wrapper label, .slidervalue {
  color: #242324;
  font-family: GeneralSans-Variable;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 19.6px */
}

.slider-inner-wrapper {
  margin-top: 14px;
}

.slidervalue {
  position: absolute;
  right: 18px;
  top: 14px;
  font-weight: 500;
}

input[type="range"] {
  width: 100%;
  margin: 14px 0px;
  height: 4px;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  background: #F9F7F5;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.21));
  border: none;
  cursor: pointer;
  border-radius: 50%;
  margin-top: -4px;
}


input[type="range"]::-moz-range-thumb {
  width: 18px;
  height: 16px;
  flex-shrink: 0;
  background: #F9F7F5;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.21));
  border: none;
  cursor: pointer;
  border-radius: 50%;
}





input[type="range"]::-moz-range-track {
  width: 100%;
  height: 4px;
  stroke-width: 4px;
  cursor: pointer;
  background: #D3D3D3;
  border-radius: 4px;
}


input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  stroke-width: 4px;
  cursor: pointer;
  background: #D3D3D3;
  border-radius: 2px;
}

input[type="range"]::-moz-range-progress {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: #9B6CFF;
  border-radius: 4px;
}

#analysisSave {
  padding: 15px 16px 16px 16px;
  border-radius: 16px;
  background: var(--Brand-2, #9B6CFF);
  box-shadow: 0px 9px 14px 0px rgba(0, 0, 0, 0.15);
  color: var(--Brand-1, #F9F7F5);
  text-align: center;
  font-family: ClashDisplay-Variable;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  width: 100%;
  margin-top: 38px;
}



input[type="range"]:focus {
  outline: none;
}

#searchresults {
  position: relative;
}

/* Styling for the circle */
.circle {
  width: 32px; /* Adjust the size as needed */
  height: 32px; /* Adjust the size as needed */
  border: 1px solid #000; /* Border color for the circle */
  border-radius: 50%; /* Makes it a circle */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Add a smooth transition effect */
  position: absolute;
  margin-left: -40px;
}

/* Styling for the selected circle (with bright green background) */
.circle.selected {
  background-color: #B5F267; /* Bright green color */
  /*border: 1px solid #B5F267;*/ /* Border color when selected */
  color: #222; /* White color for the checkmark */
}

.links {
  padding-left: 58px;
}

.links a {
  color: #9B6CFF;

}
