/**
 * @license
 *
 * Font Family: General Sans
 * Designed by: Frode Helland
 * URL: https://www.fontshare.cgeneral-sans
 * © 2023 Indian Type Foundry
 *
 * Font Styles:
 * General Sans Variable(Variable font)
 * General Sans Variable Italic(Variable font)
 * General Sans Extralight
 * General Sans Extralight Italic
 * General Sans Light
 * General Sans Light Italic
 * General Sans Regular
 * General Sans Italic
 * General Sans Medium
 * General Sans Medium Italic
 * General Sans Semibold
 * General Sans Semibold Italic
 * General Sans Bold
 * General Sans Bold Italic
 *
*/


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 700.0;
*
* available axes:

* 'wght' (range from 200.0 to 700.0)

*/

@font-face {
  font-family: 'GeneralSans-Variable';
  src: url('GeneralSans-Variable.woff2') format('woff2'),
       url('GeneralSans-Variable.woff') format('woff'),
       url('GeneralSans-Variable.ttf') format('truetype');
       font-weight: 200 700;
       font-display: swap;
       font-style: normal;
}


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 700.0;
*
* available axes:

* 'wght' (range from 200.0 to 700.0)

*/

@font-face {
  font-family: 'GeneralSans-VariableItalic';
  src: url('GeneralSans-VariableItalic.woff2') format('woff2'),
       url('GeneralSans-VariableItalic.woff') format('woff'),
       url('GeneralSans-VariableItalic.ttf') format('truetype');
       font-weight: 200 700;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'GeneralSans-Extralight';
  src: url('GeneralSans-Extralight.woff2') format('woff2'),
       url('GeneralSans-Extralight.woff') format('woff'),
       url('GeneralSans-Extralight.ttf') format('truetype');
       font-weight: 200;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'GeneralSans-ExtralightItalic';
  src: url('GeneralSans-ExtralightItalic.woff2') format('woff2'),
       url('GeneralSans-ExtralightItalic.woff') format('woff'),
       url('GeneralSans-ExtralightItalic.ttf') format('truetype');
       font-weight: 200;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'GeneralSans-Light';
  src: url('GeneralSans-Light.woff2') format('woff2'),
       url('GeneralSans-Light.woff') format('woff'),
       url('GeneralSans-Light.ttf') format('truetype');
       font-weight: 300;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'GeneralSans-LightItalic';
  src: url('GeneralSans-LightItalic.woff2') format('woff2'),
       url('GeneralSans-LightItalic.woff') format('woff'),
       url('GeneralSans-LightItalic.ttf') format('truetype');
       font-weight: 300;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'GeneralSans-Regular';
  src: url('GeneralSans-Regular.woff2') format('woff2'),
       url('GeneralSans-Regular.woff') format('woff'),
       url('GeneralSans-Regular.ttf') format('truetype');
       font-weight: 400;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'GeneralSans-Italic';
  src: url('GeneralSans-Italic.woff2') format('woff2'),
       url('GeneralSans-Italic.woff') format('woff'),
       url('GeneralSans-Italic.ttf') format('truetype');
       font-weight: 400;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'GeneralSans-Medium';
  src: url('GeneralSans-Medium.woff2') format('woff2'),
       url('GeneralSans-Medium.woff') format('woff'),
       url('GeneralSans-Medium.ttf') format('truetype');
       font-weight: 500;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'GeneralSans-MediumItalic';
  src: url('GeneralSans-MediumItalic.woff2') format('woff2'),
       url('GeneralSans-MediumItalic.woff') format('woff'),
       url('GeneralSans-MediumItalic.ttf') format('truetype');
       font-weight: 500;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'GeneralSans-Semibold';
  src: url('GeneralSans-Semibold.woff2') format('woff2'),
       url('GeneralSans-Semibold.woff') format('woff'),
       url('GeneralSans-Semibold.ttf') format('truetype');
       font-weight: 600;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'GeneralSans-SemiboldItalic';
  src: url('GeneralSans-SemiboldItalic.woff2') format('woff2'),
       url('GeneralSans-SemiboldItalic.woff') format('woff'),
       url('GeneralSans-SemiboldItalic.ttf') format('truetype');
       font-weight: 600;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'GeneralSans-Bold';
  src: url('GeneralSans-Bold.woff2') format('woff2'),
       url('GeneralSans-Bold.woff') format('woff'),
       url('GeneralSans-Bold.ttf') format('truetype');
       font-weight: 700;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'GeneralSans-BoldItalic';
  src: url('GeneralSans-BoldItalic.woff2') format('woff2'),
       url('GeneralSans-BoldItalic.woff') format('woff'),
       url('GeneralSans-BoldItalic.ttf') format('truetype');
       font-weight: 700;
       font-display: swap;
       font-style: italic;
}
